import * as LDClient from "launchdarkly-js-client-sdk";

let ldClient: LDClient.LDClient | null = null;

export const initializeLDClient = async (
  uuid: string,
  email: string,
  retries: number = 3,
  timeout: number = 5000,
) => {
  if (!navigator.onLine) {
    return null; // User is offline
  }

  if (!ldClient) {
    const context: LDClient.LDContext = {
      kind: "user",
      key: uuid,
      email,
    };

    const initializeWithTimeout = async () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const timeoutId = setTimeout(() => controller.abort(), timeout);

      try {
        ldClient = LDClient.initialize(
          process.env.REACT_APP_LAUNCH_DARKLY_ID as string,
          context,
        );

        await ldClient.waitUntilReady();
        clearTimeout(timeoutId);
        return ldClient;
      } catch (error) {
        clearTimeout(timeoutId);
        if (signal.aborted) {
          console.error("LaunchDarkly initialization timed out.");
        } else {
          console.error("LaunchDarkly initialization failed:", error);
        }
        return null;
      }
    };

    for (let attempt = 1; attempt <= retries; attempt++) {
      const client = await initializeWithTimeout();
      if (client) {
        return client;
      }
    }

    return null;
  }

  return ldClient;
};
